import { useActionData } from "@remix-run/react";
import  { type LoaderFunction } from "@vercel/remix";
import { LandingPage } from "#/app/components/landing-page";
import { action as signupAction } from "#app/routes/_auth+/signup.tsx";

// export const loader: LoaderFunction = async ({ request }) => {
//   return new Response(
//     `<!DOCTYPE html>
//     <html lang="en">
//       <head>
//         <meta charset="utf-8" />
//         <meta name="viewport" content="width=device-width,initial-scale=1" />
//         <title>Best of Me - Your Personal Growth Journey</title>
//         <meta name="description" content="Start your journey to becoming the best version of yourself with Best of Me. Get instant emotional support and personalized tools for long-term growth." />
//         <link rel="stylesheet" href="/build/_assets/tailwind-XDPBVJXN.css" />
//       </head>
//       <body>
//         <div id="app"></div>
//         <script src="/build/entry.client-QDVJXHXM.js"></script>
//       </body>
//     </html>`,
//     {
//       headers: { "Content-Type": "text/html" },
//     }
//   );
// };

export const action = signupAction;

export default function LandingRoute() {
  const actionData = useActionData<typeof signupAction>();

  return <LandingPage actionData={actionData} />;
}